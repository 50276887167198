export default url => {
    const chunkSize = 256 * 1024
    const query = (path, {csrf=null, method='POST', body=null, check=json => !!json.error ? json.error : json.success===true || 'Erreur inconnue'}) => {
        return fetch(`${url}/api${path}`, {
            method,
            credentials: 'include',
            headers: new Headers({
                'x-csrf-token': csrf,
            }),
            body,
        }).then(res => {
            if (!res.ok) {
                throw res
            }
            return res.json()
        }).then(json => {
            if (!!json.login) {
                return Promise.resolve({
                    state: {
                        login: true, error: json.error, csrf: json.csrf
                    }
                })
            } else {
                const error = check(json)
                return Promise.resolve(Object.assign({
                    state: { csrf: json.csrf },
                    json,
                }, error !== true ? { error } : {}))
            }
        }).catch(err => {
            return Promise.resolve({
                error: err,
                state: { },
            })
        })
    }

    const retries = []

    const processRetries = () => {
        while (retries.length > 0) {
            const retry = retries.shift()
            console.log("retrying", retry)
            retry.call(retry.args)
        }
    }

    setInterval(processRetries, 5000)

    const finishImage = ({ name, id, csrf, event }) => {
        const body = new FormData()
        body.append('name', name)
        body.append('id', id)
        query(
            `/upload/${event}`, { body, csrf }
        ).then(({ state, json, error }) => {
            if (!error) {
                postMessage({
                    uploaded: json.result.find(i => i.id === id),
                    csrf: state.csrf,
                })
            } else {
                console.error("finish error", id, error)
                retries.push({
                    call: finishImage, args: { name, id, csrf, event }
                })
            }
        })
    }

    const uploadChunk = ({ event, csrf, id, file, offset, name, size }) => {
        const end = Math.min(offset + chunkSize, file.size)
        const chunk = file.slice(
            offset, end, 'application/octet-stream'
        )
        const body = new FormData()
        body.append('info', JSON.stringify({ offset, size }))
        body.append(0, chunk)
        query(
            `/chunk/${event}/${id}`, { body, csrf }
        ).then(({ state, json, error }) => {
            if (!error) {
                postMessage({
                    csrf: state.csrf,
                    progress: { size: end - offset, id }
                })
                if (json.complete === true) {
                    finishImage({
                        id, name, csrf: state.csrf, event
                    })
                } else {
                    uploadChunk({
                        event, csrf, id, offset: end, file, name, size
                    })
                }
            } else {
                console.error("upload error", id, error)
                retries.push({
                    call: uploadChunk, args: {
                        event, csrf, id, file, offset, name, size
                    }
                })
            }
        })
    }

    const uploadImage = ({ event, csrf, file, id }) => {
        uploadChunk({
            event, csrf, id, file, offset: 0, name: file.name, size: file.size
        })
    }

    // https://stackoverflow.com/questions/7584794/accessing-jpeg-exif-rotation-data-in-javascript-on-the-client-side/32490603#32490603
    const getOrientation = (file, cb) => {
        const reader = new FileReader();
        reader.onload = e => {
            const view = new DataView(e.target.result);
            if (view.getUint16(0, false) !== 0xFFD8) {
                return cb(-2)
            }
            const length = view.byteLength
            let offset = 2
            while (offset < length)
            {
                if (view.getUint16(offset+2, false) <= 8) return -1
                const marker = view.getUint16(offset, false)
                offset += 2
                if (marker === 0xFFE1) {
                    if (view.getUint32(offset += 2, false) !== 0x45786966) {
                        return cb(-1)
                    }

                    const little = view.getUint16(offset += 6, false) === 0x4949
                    offset += view.getUint32(offset + 4, little)
                    const tags = view.getUint16(offset, little)
                    offset += 2
                    for (var i = 0; i < tags; i++) {
                        if (view.getUint16(offset + (i * 12), little) === 0x0112) {
                            return cb(view.getUint16(offset + (i * 12) + 8, little))
                        }
                    }
                } else if ((marker & 0xFF00) !== 0xFF00) {
                    break
                } else {
                    offset += view.getUint16(offset, false)
                }
            }
            return cb(-1)
        }
        return reader.readAsArrayBuffer(file)
    }

    const createImage = ({ image, csrf, event, file }) => {
        const body = new FormData()
        body.append("data", JSON.stringify(image))
        query(`/update/${event}/${image.id}`, { csrf, body }).then(
            ({ state, json, error }) => {
                if (!error) {
                    postMessage({ created: json, state })
                    uploadImage({
                        event, csrf: json.csrf, file: file, id: image.id,
                    })
                } else {
                    console.error("create error", image.id, error)
                    retries.push({
                        call: createImage, args: { image, csrf, event, file }
                    })
                }
            }
        )
    }

    const loadImage = ({ id, dimensions, comment, file, event, csrf }) =>
        getOrientation(file, orientation => {
            const crop = {
                rotation: 0, flop: false, zoom: 1,
            }
            switch(orientation) {
                case 2:
                    crop.flop = true
                    break
                case 7:
                    crop.flop = true
                    crop.rotation = 90
                    break
                case 4:
                    crop.flop = true
                    crop.rotation = 180
                    break
                case 5:
                    crop.flop = true
                    crop.rotation = 270
                    break
                case 1:
                    break
                case 8:
                    crop.rotation = 270
                    break
                case 3:
                    crop.rotation = 180
                    break
                case 6:
                    crop.rotation = 90
                    break
                default:
                    break
            }
            const image = {
                id,
                dimensions,
                crop,
                comment,
            }
            createImage({ image, csrf, event, file })
        })

    // eslint-disable-next-line no-restricted-globals
    self.addEventListener('message', e => {
        if (!e) return
        const { create, event, csrf } = e.data
        if (!!create) {
            const { id, dimensions, file, comment } = create
            loadImage({
                id,
                dimensions,
                file,
                comment,
                event,
                csrf,
            })
        }
    })
}
