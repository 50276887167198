import React from 'react'
import Cropper from 'react-easy-crop'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import CropRotateIcon from '@material-ui/icons/CropRotate'
import RotateLeftIcon from '@material-ui/icons/RotateLeft'
import RotateRightIcon from '@material-ui/icons/RotateRight'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import Switch from '@material-ui/core/Switch'
import ErrorIcon from '@material-ui/icons/Error'
import Slide from '@material-ui/core/Slide'
import Dialog from '@material-ui/core/Dialog'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import CloseIcon from '@material-ui/icons/Close'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import SaveIcon from '@material-ui/icons/Save'
import Slider from '@material-ui/lab/Slider'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import CircularProgress from '@material-ui/core/CircularProgress'
import LinearProgress from '@material-ui/core/LinearProgress'
import hash from 'object-hash'

function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class Icon extends React.Component {
    state = {
        loading: true,
        error: false,
    }

    render() {
        const { loading, error } = this.state
        const { src, style = {}, alt } = this.props
        if (error) {
            return <div>{alt}</div>
        } else {
            return (
                <div>
                    <img
                        style={{
                            ...style,
                            display: loading ? "none" : undefined,
                        }}
                        className="eventicon"
                        alt={alt}
                        src={src}
                        onLoad={() =>
                            this.setState({ loading: false })
                        }
                        onError={() =>
                            this.setState({ error: true, loading: false })
                        } />
                </div>
            )
        }
    }
}

function updateCanvas(rotation, flop) {
    const ctxt = this.canvas.current.getContext('2d')
    const invert = rotation === 90 || rotation === 270
    const { naturalWidth, naturalHeight } = this.img.current
    const rect = {
        w: invert ? naturalHeight : naturalWidth,
        h: invert ? naturalWidth : naturalHeight,
    }
    this.canvas.current.width = rect.w
    this.canvas.current.height = rect.h
    ctxt.translate(rect.w / 2, rect.h / 2)
    ctxt.rotate(rotation * Math.PI / 180)
    ctxt.scale(flop ? -1 : 1, 1)
    ctxt.drawImage(
        this.img.current,
        -naturalWidth / 2,
        -naturalHeight / 2,
    )
    return this.canvas.current.toDataURL('image/jpeg')
}

class Polaroid extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            w: null,
            h: null,
            comment: props.comment,
            src: null,
            ...props.dimensions,
        }
        this.img = React.createRef()
        this.canvas = React.createRef()
        this.updateCanvas = updateCanvas.bind(this)
    }

    componentDidUpdate = (props, state) => {
        if (props.view === true) {
            return
        }
        const oldRotation = props.crop ? props.crop.rotation : undefined
        const newRotation = this.props.crop ? this.props.crop.rotation : undefined
        const oldFlop = props.crop ? props.crop.flop : undefined
        const newFlop = this.props.crop ? this.props.crop.flop : undefined
        if ((newRotation !== oldRotation) ||
            (newFlop !== oldFlop)) {
            if (newFlop === true || newRotation !== 0) {
                this.setState({
                    src: this.updateCanvas(newRotation, newFlop)
                })
            }
        }
    }

    render() {
        const {
            src, view = false, comment, crop, published, onDelete, onLoad, onCrop,
            onComment, onPublish, dpi = 96, areas = {
                total: { w: 3.483, h: 4.233 }, photo: { w: 3.024, h: 3.108 }
            }, uploading, updating, dimensions = null, uploaded = 0, size = 0
        } = this.props
        const width = areas.photo.w * dpi
        const height = areas.photo.h * dpi
        const margin = (areas.total.w - areas.photo.w) * 0.5 * dpi
        const rotation = crop ? crop.rotation : 0
        const flop = crop ? crop.flop : false
        const canvas = rotation !== 0 || flop === true
        const invert = rotation === 90 || rotation === 270
        const { w, h } = this.state.w && this.state.h && invert ?
            { w: this.state.h, h: this.state.w } : this.state
        const ratio = w && h ? {
            x: (w > h ? height / h : width / w),
            y: (h > w ? width / w : height / h),
        } : { x: 1, y: 1 }
        const zoom = {
            x: (crop ? crop.zoom : 1) * ratio.x,
            y: (crop ? crop.zoom : 1) * ratio.y,
        }
        const image = w && h ? {
            w: w * zoom.x,
            h: h * zoom.y,
        } : { w: width, h: height }
        const defaultTranslate = {
            x: w > h ?
                - (w - h) / 2 : 0,
            y: h > w ?
                - (h - w) / 2 : 0,
        }
        const translate = w && h ? {
            x: crop && crop.x !== undefined ?
                -crop.x * zoom.x : defaultTranslate.x * zoom.x,
            y: crop && crop.y !== undefined ?
                -crop.y * zoom.y : defaultTranslate.y * zoom.y,
        } : { x: 0, y: 0 }
        const translation = `translate(${translate.x}px, ${translate.y}px)`
        const q = {
            ...image,
            r: rotation,
            f: flop,
        }
        const qs = Object.keys(q).reduce((qs, k) => ({
            ...qs,
            [k]: q[k].toString(),
        }), {})
        const url = view === true && !!dimensions && !!w && !!h &&
            `${src}?w=${q.w}&h=${q.h}` +
            `&r=${q.r}&f=${q.f}&hh=${hash(qs)}`
        return (
            <Paper style={{
                margin: "5px",
                position: "relative",
                height: `${areas.total.h * dpi}px`,
                width: `${areas.total.w * dpi}px`,
                overflow: "hidden",
            }}>
                {(!!uploading || !!updating) && [
                    <div key={'progress'} style={{
                        position: 'absolute',
                        zIndex: 1200,
                        top: '0px',
                        width: '100%',
                    }}>
                        <LinearProgress
                            color={'primary'}
                            variant={
                                !!uploaded && !!size ?
                                    'determinate' :
                                    'indeterminate'
                            }
                            value={
                                !!uploaded && !!size ?
                                    uploaded * 100. / size : undefined
                            } />
                    </div>,
                    !!uploading && uploading !== true && (
                        <img
                            style={{ display: 'none' }}
                            key={'uploading-image'}
                            alt=""
                            src={uploading}
                            onLoad={e => !!onLoad && onLoad(e)} />
                    )]}
                <div style={{
                    zIndex: 0,
                    position: "relative",
                    height: `${areas.total.h * dpi}px`,
                    width: `${areas.total.w * dpi}px`,
                }}>
                    <Paper style={{
                        zIndex: 0,
                        position: "absolute",
                        top: `${margin}px`,
                        left: `${margin}px`,
                        width: `${width}px`,
                        height: `${height}px`,
                        overflow: "hidden"
                    }}>
                        {!url ?
                            <img
                                src={src}
                                alt=""
                                style={{
                                    ...canvas ? { display: "none" } : {
                                        width: `${image.w}px`,
                                        height: `${image.h}px`,
                                        transform: translation,
                                    },
                                }}
                                ref={this.img}
                                onLoad={e => {
                                    this.setState({
                                        w: e.target.naturalWidth,
                                        h: e.target.naturalHeight,
                                        src: !canvas ?
                                            src : this.updateCanvas(
                                                rotation,
                                                flop,
                                            ),
                                    })
                                    !canvas && onLoad && onLoad(e)
                                }} /> :
                            <img
                                style={{
                                    transform: translation,
                                }}
                                src={url}
                                alt=""
                                onLoad={e => onLoad && onLoad(e)} />}
                        {canvas && [
                            <img
                                key="img"
                                alt=""
                                style={{
                                    ...!!this.state.src ?
                                        {} : { display: 'none' },
                                    width: `${image.w}px`,
                                    height: `${image.h}px`,
                                    transform: translation,
                                }}
                                src={this.state.src}
                                onLoad={e => {
                                    URL.revokeObjectURL(this.state.src)
                                    onLoad && onLoad(e)
                                }} />,
                            <canvas
                                key="canvas"
                                style={{
                                    display: 'none',
                                }} ref={this.canvas} />]}
                    </Paper>
                    <div style={{
                        zIndex: 0,
                        position: "absolute",
                        top: `${margin + height}px`,
                        left: `${margin}px`,
                        width: `${areas.total.w * dpi - 2 * margin}px`,
                        display: "flex",
                        justifyContent: "space-around",
                        flexWrap: "wrap",
                        alignItems: "center",
                    }}>
                        {!view ?
                            [<TextField
                                key={'comment'}
                                onChange={event => {
                                    if (event.target.value.length <= 96) {
                                        this.setState({ comment: event.target.value })
                                    }
                                }}
                                margin={'dense'}
                                className={'comment'}
                                multiline
                                rows={3}
                                rowsMax={3}
                                disabled={!!updating}
                                error={this.state.comment !== comment}
                                value={this.state.comment}
                                placeholder={"Votre commentaire ici..."}
                                fullWidth />,
                            this.state.comment !== comment &&
                            <Fab
                                style={{
                                    position: 'absolute',
                                    right: `-${margin - 5}px`,
                                    zIndex: 2
                                }}
                                color={'secondary'}
                                size={'small'}
                                key={'save'}
                                disabled={!!updating}
                                onClick={() => {
                                    !!onComment &&
                                        onComment(
                                            this.state.comment,
                                        )
                                }}>
                                <SaveIcon />
                            </Fab>] : (
                                <div key="comment" className='comment'>
                                    {!!comment && comment.substring(0, 96)}
                                </div>
                            )}
                    </div>
                </div>
                {!view &&
                    <AppBar position='absolute' style={{
                        top: '0px'
                    }}>
                        <Toolbar color="inherit" variant="dense" disableGutters>
                            <Switch checked={published === true} disabled={!!uploading || !!updating} onChange={() => onPublish(!published)} />
                            <div style={{
                                fontStyle: "italic",
                                fontSize: "0.8em"
                            }}>
                                {!!uploading ? "En attente du téléchargement..." : (published ? "Publiée" : "Non publiée")}
                            </div>
                            <div style={{ flexGrow: 1 }} />
                            <IconButton onClick={onDelete} color="inherit" disabled={!!updating || !!uploading}>
                                <DeleteIcon />
                            </IconButton>
                            <IconButton onClick={onCrop} color="inherit" disabled={!!updating}>
                                <CropRotateIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>}
            </Paper>
        )
    }
}

class RotatedCropper extends React.Component {
    constructor(props) {
        super(props)
        this.canvas = React.createRef()
        this.img = React.createRef()
        this.state = {
            src: props.rotation === 0 && props.flop === false && props.image,
        }
        this.updateCanvas = updateCanvas.bind(this)
    }

    componentDidUpdate = props => {
        if (props.rotation !== this.props.rotation ||
            props.flop !== this.props.flop) {
            this.setState({
                src:
                    this.props.rotation !== 0 || this.props.flop !== false ?
                        this.updateCanvas(
                            this.props.rotation,
                            this.props.flop,
                        ) :
                        this.props.image,
            })
        }
    }

    render() {
        const { rotation, image, flop, ...props } = this.props
        const cropper = !!this.state.src &&
            <Cropper
                image={this.state.src}
                {...props} />
        return (
            <div>
                {(rotation !== 0 || flop === true) &&
                    <div style={{ display: "none" }}>
                        <canvas
                            ref={this.canvas} />
                        <img
                            src={image}
                            alt=""
                            onLoad={() => {
                                this.setState({
                                    src: this.updateCanvas(rotation, flop)
                                })
                            }}
                            ref={this.img} />
                    </div>}
                {cropper}
            </div>
        )
    }
}

class ImageCropper extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            crop: props.crop ? {
                x: props.crop.x || 0,
                y: props.crop.y || 0,
            } : { x: 0, y: 0 },
            zoom: props.crop ? props.crop.zoom : 1,
            open: true,
            saving: false,
            rotation: props.crop ? props.crop.rotation : 0,
            flop: props.crop ? props.crop.flop === true : false,
        }
    }

    onCropChange = crop => {
        this.setState({ crop })
    }

    onCropComplete = (_, area) => {
        this.area = area
    }

    onDialogClose = () => {
        this.setState({ open: false })
    }

    onExited = () => {
        if (!!this.save) {
            this.save = false
            !!this.props.onCropComplete && this.props.onCropComplete({
                x: this.state.crop.x,
                y: this.state.crop.y,
                area: this.area,
                zoom: this.state.zoom,
                rotation: this.state.rotation,
                flop: this.state.flop,
            })
        } else {
            !!this.props.onCropCancel && this.props.onCropCancel()
        }
    }

    onSave = () => {
        this.save = true
        this.setState({ saving: true, open: false })
    }

    onZoomChange = zoom => {
        this.setState({ zoom })
    }

    render() {
        const {
            minZoom = 1, maxZoom = 5, zoomSpeed = 0.1, aspect = 3.024 / 3.108,
            onError, src
        } = this.props
        return (
            <Dialog
                fullScreen
                open={this.state.open}
                onClose={this.onDialogClose}
                onExited={this.onExited}
                TransitionComponent={Transition}>
                <AppBar style={{ position: 'relative' }} color='secondary'>
                    <Toolbar>
                        <IconButton color="inherit" onClick={this.onDialogClose} aria-label="Close">
                            <CloseIcon />
                        </IconButton>
                        <div style={{ flexGrow: 1 }} />
                        <div style={{ width: '300px' }}>
                            <Slider
                                value={this.state.zoom}
                                onChange={(e, zoom) => this.setState({ zoom })}
                                max={maxZoom}
                                min={minZoom}
                                step={zoomSpeed} />
                        </div>
                        <div style={{
                            width: '50px',
                            marginRigth: '10px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                            {"Zoom"}
                        </div>
                        <div style={{
                            width: '50px',
                            marginLeft: '10px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>{new Intl.NumberFormat(undefined, {
                            maximumFractionDigits: 2
                        }).format(this.state.zoom)}x
                        </div>
                        <IconButton color="inherit" onClick={() => this.setState({ rotation: this.state.rotation === 0 ? 270 : this.state.rotation - 90 })}>
                            <RotateLeftIcon />
                        </IconButton>
                        <IconButton color="inherit" onClick={() => this.setState({ rotation: this.state.rotation === 270 ? 0 : this.state.rotation + 90 })}>
                            <RotateRightIcon />
                        </IconButton>
                        <Button
                            variant="contained"
                            color="primary" onClick={this.onSave}
                            disabled={this.state.saving}>
                            Terminer
                        </Button>
                    </Toolbar>
                </AppBar>
                <RotatedCropper
                    image={src}
                    zoom={this.state.zoom}
                    crop={this.state.crop}
                    flop={this.state.flop}
                    rotation={this.state.rotation}
                    aspect={aspect}
                    zoomSpeed={zoomSpeed}
                    onImgError={onError}
                    onZoomChange={this.onZoomChange}
                    onCropChange={this.onCropChange}
                    onCropComplete={this.onCropComplete}
                    minZoom={minZoom}
                    maxZoom={maxZoom} />
            </Dialog>
        )
    }
}

class RemoveDialog extends React.Component {
    state = { open: true }

    handleClose = confirm => () => {
        this.setState({ open: false })
        !!this.props.onClose && this.props.onClose(confirm)
    }

    render() {
        return (
            <Dialog
                open={this.state.open}
                onClose={this.handleClose(false)}>
                <DialogTitle>
                    Êtes vous sûr de vouloir supprimer cette image ?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Une fois supprimée, vous ne pourrez plus récupérer l'image en question.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose(true)} color="primary">
                        Supprimer
                    </Button>
                    <Button onClick={this.handleClose(false)} color="primary" autoFocus>
                        Conserver
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

class Images extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            cropping: null,
            removing: null,
            width: null,
        }
        this.debouncer = undefined;
        this.div = React.createRef()

        this.listenResize();
    }

    componentDidMount() {
        this.updateWidth();

    }

    listenResize = () => {
        window.addEventListener('resize', () => {
            clearTimeout(this.debouncer);
            this.debouncer = window.setTimeout(() => {
                this.updateWidth();
            }, 500);
        })
    }

    updateWidth = () => {
        const rect = this.div.getBoundingClientRect()
        const cellWidth = (96 * 3.483 + 10)
        const cols = Math.trunc(rect.width / cellWidth)
        const width = Math.max(1, cols) * cellWidth
        this.setState({ width: width })
    }

    render() {
        const {
            images, addImages, updateImage, deleteImage, uploading, onLoad
        } = this.props
        const { cropping, removing, width } = this.state
        const croppingImage = images.find(i => i.id === cropping)
        return (
            <div className="images fadein" ref={ref => this.div = ref}>
                {!!cropping &&
                    <ImageCropper
                        src={croppingImage.url}
                        crop={croppingImage.crop}
                        onCropComplete={(crop) => {
                            this.setState({ cropping: null })
                            !!updateImage && updateImage(cropping, { crop })
                        }}
                        onCropCancel={() => {
                            this.setState({ cropping: null })
                        }}
                        onError={() => this.setState({ cropping: null })}
                    />}
                {!!removing &&
                    <RemoveDialog
                        onClose={confirm => {
                            this.setState({ removing: null })
                            confirm && !!deleteImage && deleteImage(removing)
                        }} />}
                {images.length > 0 ? (
                    <div style={{ width: `${width}px` }} className="list">
                        {images.map(i => (
                            <Polaroid
                                key={i.id}
                                comment={i.comment}
                                uploading={i.uploading}
                                updating={i.updating}
                                dimensions={i.dimensions}
                                uploaded={i.uploaded}
                                size={i.size}
                                src={i.url}
                                crop={i.crop && {
                                    zoom: i.crop.zoom,
                                    x: i.crop.area ? i.crop.area.x : undefined,
                                    y: i.crop.area ? i.crop.area.y : undefined,
                                    rotation: i.crop.rotation,
                                    flop: i.crop.flop,
                                }}
                                published={i.published}
                                onCrop={() => this.setState({ cropping: i.id })}
                                onComment={(comment, cb) => {
                                    !!updateImage && updateImage(i.id, { comment }, cb)
                                }}
                                onDelete={e =>
                                    e.shiftKey === true ?
                                        !!deleteImage && deleteImage(i.id) :
                                        this.setState({ removing: i.id })
                                }
                                onPublish={publish => {
                                    !!updateImage && updateImage(i.id, { published: publish })
                                }}
                                onLoad={e => onLoad({ id: i.id, event: e })}
                            />
                        ))}
                    </div>) : (
                        <div className="list empty">
                            <ErrorIcon style={{ marginRight: 20 }} />
                            {" Pas d'images..."}
                        </div>
                    )}
                <input type='file' onChange={addImages} accept="image/*" id='contained-button-file' multiple />
                <label htmlFor="contained-button-file">
                    <Fab color="primary" component="span" disabled={!!uploading} aria-label="Add" style={{ position: 'fixed', bottom: '20px', right: '20px', zIndex: 1200 }}>
                        {!!uploading ? String(uploading) : <AddIcon />}
                    </Fab>
                    {!!uploading && (
                        <CircularProgress size={56} style={{
                            position: 'fixed',
                            bottom: '20px',
                            right: '20px'
                        }} />
                    )}
                </label>
            </div>
        )
    }
}
export { Polaroid, Icon }
export default Images
