import React, { Component } from 'react'
import Images from './Images'
import Loading from './Loading'
import Login from './Login'
import Notifications, { notify } from 'react-notify-toast'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Switch from '@material-ui/core/Switch'
import ImageWorker from './ImageWorker'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import * as colors from '@material-ui/core/colors'
import shortid from 'shortid'

import './App.css'

const toastConfig = {
    color: {
        background: '#505050',
        text: '#fff'
    },
    timeout: 4000
}

const url = window.location.origin

class WebWorker {
    constructor(worker) {
        const code = worker.toString();
        const blob = new Blob(["(" + code + ")('" + url + "')"]);
        return new Worker(URL.createObjectURL(blob));
    }
}

export default class App extends Component {
    state = {
        loading: true,
        uploading: 0,
        error: null,
        login: true,
        username: null,
        images: [],
        csrf: null,
        valid: false,
        init: true,
        event: null,
        publish: null,
        theme: {
            typography: {
                useNextVariants: true,
            },
            palette: {
            },
        },
    }

    checkOnline = () => {
        const { match } = this.props
        const event = match.params.event
        this.setState({ loading: true })
        fetch(`${url}/api/online/${event}`, { credentials: 'include' }).then(
            res => {
                if (!res.ok) {
                    throw res
                } else {
                    return res.json()
                }
            }
        ).then(json => {
            const palette = !!json.palette ? Object.keys(json.palette).reduce(
                (palette, key) => ({
                    ...palette,
                    [key]: {
                        main: colors[
                            json.palette[key].color
                        ][
                            json.palette[key].shade || 500
                        ],
                    },
                }), {},
            ) : {}
            if (json.login === true || !!json.error) {
                this.setState({
                    login: true,
                    init: false,
                    loading: false,
                    csrf: json.csrf,
                    error: json.error || "Veuillez choisir un nom",
                    valid: json.eventValid,
                    event: json.eventValid && event,
                    theme: {
                        ...this.state.theme,
                        palette: {
                            ...this.state.theme.palette,
                            ...palette,
                        }
                    }
                })
            } else {
                this.setState({
                    login: false,
                    loading: false,
                    init: false,
                    error: null,
                    valid: json.eventValid,
                    event: json.eventValid && event,
                    username: json.username,
                    publish: json.publish !== undefined ? json.publish : true,
                    images: json.images.map(
                        image => ({
                            ...image,
                            url: `${url}/image/${event}/${image.id}`
                        })
                    ),
                    csrf: json.csrf,
                    theme: {
                        ...this.state.theme,
                        palette: {
                            ...this.state.theme.palette,
                            ...palette,
                        }
                    }
                })
            }
        }).catch(error => {
            console.error(error)
            this.notify("Impossible de joindre le serveur", "error")
            this.setState({
                error: "Une erreur inattendue s'est produite"
            })
        })
    }

    query = (path, { method = 'POST', body = null, check = json => !!json.error ? json.error : json.success === true || 'Erreur inconnue' }) => {
        return fetch(`${url}/api${path}`, {
            method,
            credentials: 'include',
            headers: new Headers({
                'x-csrf-token': this.state.csrf,
            }),
            body,
        }).then(res => {
            if (!res.ok) {
                throw res
            }
            return res.json()
        }).then(json => {
            if (!!json.login) {
                return Promise.resolve({
                    state: {
                        login: true, error: json.error, csrf: json.csrf
                    }
                })
            } else {
                const error = check(json)
                if (error !== true) {
                    this.notify(error, 'error')
                }
                return Promise.resolve(Object.assign({
                    state: { csrf: json.csrf },
                    json,
                }, error !== true ? { error } : {}))
            }
        }).catch(err => {
            console.error(err)
            return Promise.resolve({
                error: err,
                state: {},
            })
        })
    }

    receiveImage = e => {
        console.log("receive from worker", e.data)
        if (!!e.data.created) {
            const { created } = e.data
            this.setState({
                images: this.state.images.map(
                    image => image.id === created.image.id ?
                        { ...image, ...created.image } : image
                )
            })
        } else if (!!e.data.uploaded) {
            const { uploaded, csrf } = e.data
            this.setState({
                csrf,
                images: this.state.images.map(
                    image => {
                        if (image.id !== uploaded.id) {
                            return image
                        } else {
                            if (!uploaded.error) {
                                console.log("finished upload", image)
                                const uploading =
                                    `${url}/image/${this.state.event}` +
                                    `/${image.id}`
                                return {
                                    ...uploaded,
                                    ...image,
                                    published: this.state.publish,
                                    uploading,
                                }
                            } else {
                                this.notify("impossible de télécharger")
                                return {
                                    ...image,
                                    error: true,
                                }
                            }
                        }
                    }
                ).filter(
                    image => !image.error
                ),
            })
        } else if (!!e.data.progress) {
            const { progress, csrf } = e.data
            this.setState({
                csrf,
                images: this.state.images.map(
                    image => image.id === progress.id ?
                        { ...image, uploaded: image.uploaded + progress.size } :
                        image
                )
            })
        }
    }

    componentDidMount() {
        if (this.eventValid) {
            this.checkOnline()
            window.addEventListener("beforeunload", this.warnUnload)
            this.workers = Array(4).fill().map(() => {
                const worker = new WebWorker(ImageWorker)
                worker.addEventListener("message", this.receiveImage)
                return worker
            })
            this.nextWorker = 0
        }
    }

    warnUnload = e => {
        if (this.state.images.any(i => !!i.uploading)) {
            e.returnValue = `Vous êtes encore en train de télécharger des images, êtes-vous sûr.e de vouloir quitter la page ??`
            return e.returnValue
        }
    }

    toast = notify.createShowQueue()

    notify = (
        message,
        type = 'custom',
        timeout = toastConfig.timeout,
        color = toastConfig.color
    ) => {
        console.log(type, message)
        this.toast(message, type, timeout, color)
    }

    post = msg => {
        this.workers[this.nextWorker].postMessage(msg)
        this.nextWorker = (this.nextWorker + 1) % this.workers.length
    }

    addImages = e => {
        const { files } = e.target;

        const images = Array.from(files).reduce((images, file) => {
            const id = shortid.generate()
            console.log("uploading image", id, file)
            const url = URL.createObjectURL(file)
            const image = {
                id,
                comment: "",
                dimensions: null,
                crop: null,
            }
            return [{
                ...image,
                url,
                uploading: true,
                loading: file,
                size: file.size,
                uploaded: 0,
            }, ...images]
        }, this.state.images);

        // clean target
        e.target.value = "";

        this.setState({ images })
    }

    onLoadImage = ({ id, event }) => {
        const { images } = this.state;
        const image = images.find(i => i.id === id)
        const loading = image && image.loading
        const uploading = image && !!image.uploading && image.uploading !== true
        if (loading) {
            const dimensions = {
                w: event.target.naturalWidth,
                h: event.target.naturalHeight,
            }
            this.setState({
                images: images.map(i => {
                    if (i.id === id) {
                        return {
                            ...i,
                            loading: undefined,
                        }
                    } else {
                        return i
                    }
                }),
            })
            this.post({
                create: {
                    id: image.id,
                    dimensions,
                    file: image.loading,
                    comment: image.comment,
                },
                event: this.state.event,
                csrf: this.state.csrf,
            })
        } else if (uploading) {
            this.setState({
                images: images.map(i => {
                    if (i.id === id) {
                        URL.revokeObjectURL(i.url)
                        return {
                            ...i,
                            url: image.uploading,
                            uploading: false,
                        }
                    } else {
                        return i
                    }
                }),
            })
        }
    }

    filter = id => {
        return this.state.images.filter(other => id !== other.id)
    }

    removeImage = id => {
        const { event } = this.state
        this.query(`/delete/${event}/${id}`, {}).then(({ state, error }) => {
            this.setState(
                Object.assign(
                    state,
                    !error ? { images: this.filter(id) } : {}
                )
            )
        })
        this.setState({
            images: this.state.images.map(
                image => image.id === id ? { ...image, updating: true } : image
            )
        })
    }

    onPublish = publish => {
        const body = new FormData()
        const { event } = this.state
        body.append("publish", JSON.stringify(publish))
        this.query(`/publish/${event}`, { body }).then(
            ({ state, error }) =>
                this.setState({
                    ...state,
                    ...(!error ? { publish } : {}),
                })
        )
    }

    updateImage = (id, props, cb) => {
        const { event } = this.state
        const body = new FormData()
        body.append("data", JSON.stringify(props))
        this.query(`/update/${event}/${id}`, { body }).then(
            ({ state, json, error }) => {
                !!error && this.notify(error, "error")
                this.setState(Object.assign(state, {
                    images: this.state.images.map(
                        image => image.id === id ? {
                            ...image,
                            ...(!error ? json.image : {}),
                            updating: false
                        } : image,
                    )
                }))
                !!cb && cb()
            }
        )
        this.setState({
            images: this.state.images.map(
                image => image.id === id ? { ...image, updating: true } : image
            )
        })
    }

    onLogin = data => {
        const formData = new FormData()
        formData.append("event", this.state.event)
        formData.append("username", data.username)
        this.setState({ loading: true })
        fetch(`${url}/api/login`, {
            method: 'POST', headers: new Headers({
                'x-csrf-token': this.state.csrf,
            }), credentials: 'include', body: formData,
        }).then(res => {
            if (!res.ok) {
                throw res
            } else {
                return res.json()
            }
        }).then(json => {
            if (!!json.error) {
                this.setState({
                    csrf: json.csrf,
                    error: json.error,
                    loading: false,
                })
            } else {
                this.setState({
                    csrf: json.csrf,
                    login: false,
                    error: null,
                    loading: false,
                    username: json.username,
                    images: json.images,
                })
            }
        }).catch(err => {
            this.setState({
                error: "Une erreur inattendue s'est produite",
            })
        })
    }

    render() {
        const {
            images, loading, login, error, theme, valid, init,
            username, publish
        } = this.state
        const { match } = this.props
        this.eventValid =
            !!match && !!match.params && !!match.params.event &&
            /^[0-9a-z]{5,32}$/i.test(match.params.event)
        const msg = this.eventValid ? error :
            "Vérifiez l'URL de l'évènement"
        const event = !!match && !!match.params && match.params.event
        const content = () => {
            switch (true) {
                case init && this.eventValid:
                    return <Loading />
                case login === true || !valid:
                    return (
                        <Login
                            valid={this.eventValid && valid}
                            event={match.params.event}
                            onLogin={this.onLogin}
                            loading={loading && this.eventValid}
                            fields={{
                                username: {
                                    placeholder: "Votre nom",
                                    error: msg,
                                    value: "",
                                }
                            }}
                            text="Participer !"
                        />
                    )
                case login === false:
                    return (
                        <Images
                            images={images}
                            username={username}
                            addImages={this.addImages}
                            deleteImage={this.removeImage}
                            updateImage={this.updateImage}
                            uploading={this.state.uploading}
                            onLoad={this.onLoadImage}
                        />
                    )
                default:
                    return <div />
            }
        }
        return (
            <div className='container' style={{ height: '100vh' }}>
                <MuiThemeProvider
                    theme={createMuiTheme(theme)}>
                    {!!username && (
                        <AppBar position={'absolute'}>
                            <Toolbar>
                                <Avatar alt={username} style={{ marginRight: 20 }}>
                                    {username[0].toUpperCase()}
                                </Avatar>
                                <Typography style={{ flexGrow: 1 }} color={'inherit'}>
                                    <span style={{
                                        color: "rgba(0,0,0,0.45)",
                                    }}>
                                        {`${event} / `}
                                    </span>
                                    <span>
                                        {username}
                                    </span>
                                </Typography>
                                <Typography color='inherit'>
                                    {publish === true ?
                                        "Images publiées immédiatement" :
                                        "Les images doivent être publiées"}
                                </Typography>
                                <Switch
                                    checked={publish === true}
                                    onChange={() =>
                                        this.onPublish(!(publish === true))
                                    } />
                            </Toolbar>
                        </AppBar>
                    )}
                    <Notifications />
                    {content()}
                </MuiThemeProvider>
            </div>
        )
    }
}
