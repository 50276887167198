import React from 'react'
import { Polaroid } from './Images'
import io from 'socket.io-client'
import Loading from './Loading'
import Login from './Login'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import * as colors from '@material-ui/core/colors'

const url = window.location.origin

class Holder extends React.Component {
    constructor(props) {
        super(props)
        const { jitter, destination } = props
        this.state = {
            finished: false,
            x: props.x,
            y: props.y,
            z: 1,
            o: 1,
            r: (destination.rr + 180) % 360,
            xx: destination.xx + (Math.random() * jitter * 2) - jitter,
            yy: destination.yy + (Math.random() * jitter * 2) - jitter,
        }
    }

    tick = () => {
        const { x, y, r, xx, yy } = this.state
        const { destination : { rr }, time } = this.props
        const timeNow = Date.now() / 1000.0
        const elapsed = timeNow - this.start
        const stepTime = timeNow - this.lastFrame
        this.lastFrame = timeNow
        const remaining = time.move - elapsed
        const step = {
            x: ( xx - x ) / ( remaining / stepTime ),
            y: ( yy - y ) / ( remaining / stepTime ),
            r: 540 / ( time.move / stepTime ),
        }
        const finished = elapsed >= time.move
        if (finished) {
            this.tickFrame = null
            this.setState({
                finished,
                x: xx,
                y: yy,
                z: 1,
                r: rr,
                o: 1,
            })
        } else {
            if (elapsed > 0.01) {
                this.setState({
                    x: Math.min(x + step.x, xx),
                    y: Math.min(y + step.y, yy),
                    r: (r + step.r) % 360,
                })
            }
            this.tickFrame = requestAnimationFrame(this.tick)
        }
    }

    hide = () => {
        if (!this.removed) {
            const { time } = this.props
            const timeNow = Date.now() / 1000.0
            const tickDuration = timeNow - this.hideLastFrame
            this.hideLastFrame = timeNow
            const elapsed = timeNow - this.hideStart
            const step = 1.0 / ( time.hide / tickDuration )
            const o = this.state.o - step
            if (o <= 0) {
                this.hideFrame = null
                this.props.onRemove()
                clearTimeout(this.hideTimeout)
                cancelAnimationFrame(this.hideFrame)
                this.removed = true
            } else {
                if (elapsed > 0.01) {
                    this.setState({
                        o
                    })
                }
                this.hideFrame = requestAnimationFrame(this.hide)
            }
        }
    }

    componentDidUpdate = (prevProps) => {
        if (!prevProps.remove && this.props.remove) {
            this.hideStart = Date.now() / 1000.0
            this.hideLastFrame = this.hideStart
            this.hideFrame = requestAnimationFrame(this.hide)
            this.hideTimeout = setTimeout(
                this.hide, this.props.time.hide * 1000
            )
        } else if (
            this.state.finished === true && (
                this.state.xx !== this.state.x ||
                this.state.yy !== this.state.y ||
                this.props.destination.rr !== this.state.r
            )
        ) {
            this.setState({
                x: this.state.xx,
                y: this.state.yy,
                r: this.props.destination.rr,
            })
        } else if ( !prevProps.start && this.props.start === true ) {
            this.start = Date.now() / 1000.0
            this.lastFrame = this.start
            this.tickFrame = requestAnimationFrame(this.tick)
        }
    }

    componentWillUnmount() {
        this.tickFrame && cancelAnimationFrame(this.tickFrame)
        this.hideFrame && cancelAnimationFrame(this.hideFrame)
    }

    render() {
        const { children } = this.props
        const { x, y, z, o, r } = this.state
        return (
            <div style={{
                position: 'absolute',
                opacity: `${o}`,
                bottom: `${y}px`,
                left: `${x}px`,
                transform: `rotate(${r}deg) scale(${z})`,
            }}>
            {children}
            </div>
        )
    }
}

class Stack extends React.Component {
    render() {
        const {
            x, y, images, start, dpi, margin,
            onRemove, time, onLoad, event, jitter
         } = this.props
        return (
            <div>
                {images.map((image, i) => image ?
                    <Holder
                        x={start.x}
                        y={start.y}
                        time={time}
                        jitter={jitter}
                        destination={{
                            xx: x + margin / 2,
                            yy: y + margin / 2,
                            rr: image.rotation,
                        }}
                        start={image.start}
                        key={image.id}
                        remove={image.remove}
                        onRemove={onRemove(image.id)}>
                        <Polaroid
                            dpi={dpi}
                            comment={image.comment}
                            dimensions={image.dimensions}
                            crop={image.crop && {
                                zoom: image.crop.zoom,
                                ...image.crop.area ? image.crop.area : { },
                                rotation: image.crop.rotation,
                                flop: image.crop.flop,
                            }}
                            src={`${url}/image/${event}/${image.id}`}
                            view={true}
                            onLoad={onLoad(image.id)} />
                    </Holder> : <div key={`empty-${i}`} />
                )}
            </div>
        )
    }
}

export default class Viewer extends React.Component {
    constructor(props) {
        super(props)
        this.div = React.createRef()
        this.timeoutId = null
        this.images = Array(0)
        this.newImages = Array(0)
        this.nextImage = 0
        this.state = {
            login: true,
            password: null,
            error: null,
            csrf: null,
            loading: true,
            stacks: [],
            margin: null,
            rows: null,
            cols: null,
            cell: null,
            dpi: null,
            startx: null,
            count: null,
            stackSize: null,
            time: null,
            interval: null,
            jitter: 0,
            rotationRange: 40,
            init: true,
            valid: false,
            theme: {
                typography: {
                    useNextVariants: true,
                },
                palette: {
                },
            },
        }
    }

    componentWillUnmount = () => {
        if (this.timeoutId !== null) {
            clearInterval(this.timeoutId)
            this.timeoutId = null
        }
    }

    tick = () => {
        console.log("tick", "known", this.images.length, "new", this.newImages.length, "count", this.state.count, "next", this.nextImage)
        const { stackSize, rotationRange } = this.state
        const newImage = this.newImages.length > 0
        if (this.images.length === 0) {
            this.nextImage = 0
        } else {
            this.nextImage = (this.nextImage + 1) % this.images.length
        }
        let nextImage = this.images[this.nextImage]
        let stacks = this.state.stacks

        if (this.toRemove.length > 0) {
            this.images = this.images.filter(
                i => this.toRemove.find(j => j === i.id) === undefined
            )
        }

        if (newImage) {
            nextImage = this.newImages.shift()
            this.images = this.images.slice(0, this.nextImage)
                .concat([nextImage], this.images.slice(this.nextImage))
            console.log("next image to display is new image", this.nextImage, nextImage.id)
        } else if (!!nextImage) {
            console.log("next image to display is known", this.nextImage, nextImage.id)
        }

        if (this.images.length > 1) {
            let lastImage = null
            if (this.images.length > this.state.count) {
                lastImage = (this.nextImage - this.state.count)
                while (lastImage < 0) {
                    lastImage += this.images.length
                }
            } else if (this.newImages.length === 0) {
                lastImage = (this.nextImage + 1) % this.images.length
            }

            if (lastImage !== null) {
                const toRemove = this.images[lastImage].id
                this.toRemove.push(toRemove)
                console.log("image to remove is", lastImage, toRemove)
            }
        }

        stacks = stacks.map(s => s.map(
            i => i && this.toRemove.find(j => j === i.id) !== undefined ?
                {...i, remove: true } :
                i
        ))

        this.toRemove = []

        if (newImage || this.images.length > 1) {
            const levels = Array(stackSize).fill().map(
                (_, level) => ({
                    level, n: stacks.filter(
                        s =>
                            s[level] === null ||
                            s[level].remove === true
                    ).length,
                })
            )
            const freeLevels = levels.filter(level => level.n > 0)
            const freeLevel = freeLevels.pop()
            let stack = null
            if (!!freeLevel) {
                const freeStacks = stacks.map(
                    (s, i) => ({s, i})
                ).filter(
                    ({ s }) =>
                        s[freeLevel.level] === null ||
                        s[freeLevel.level].remove === true
                )
                if (freeStacks.length > 1) {
                    stack = freeStacks[
                        Math.trunc(Math.random() * freeStacks.length)
                    ].i
                } else if (freeStacks.length === 1) {
                    stack = freeStacks[0].i
                }
            } else {
                stack = Math.trunc(Math.random() * stacks.length)
            }
            const allRotations = Array(
                rotationRange + 1
            ).fill().map(
                (_, i) => (i - rotationRange / 2),
            )
            const rotations = stacks[stack].filter(
                i => i !== null,
            ).map(
                i => i.rotation
            )
            const availableRotations = allRotations.filter(
                r => rotations.findIndex(rr => rr === r) === -1
            )
            const rotation = availableRotations[
                Math.trunc(Math.random() * availableRotations.length)
            ]
            this.setState({
                stacks: stacks.map(
                    (s, i) => {
                        if (i === stack) {
                            const emptySpot = s.findIndex(j => j === null)
                            if (emptySpot !== -1) {
                                return [
                                    ...s.slice(0, emptySpot),
                                    ...s.slice(emptySpot + 1), {
                                        ...nextImage,
                                        rotation,
                                        remove: false,
                                    }
                                ]
                            } else {
                                return [
                                    ...s,
                                    {...nextImage, remove: false, rotation },
                                ]
                            }
                        } else {
                            return s
                        }
                    }
                )
            })
        } else {
            console.log("not enough image to do anything")
            this.setState({
                stacks,
            })
        }
    }

    getImages = () => {
        this.setState({ loading: true })
        fetch(`${url}/api/images/${this.props.match.params.event}`).then(
            res => res.json()
        ).then(json => {
            const palette = !!json.palette ? Object.keys(json.palette).reduce(
                (palette, key) => ({
                    ...palette,
                    [key]: {
                        main: colors[
                            json.palette[key].color
                        ][
                            json.palette[key].shade||500
                        ],
                    },
                }), {},
            ) : {}
            if (json.login === true || !!json.error) {
                this.setState({
                    login: true, csrf: json.csrf, loading: false,
                    error: json.error || "Veuillez vous identifier",
                    valid: json.eventValid,
                    init: false,
                    theme: {
                        ...this.state.theme,
                        palette: {
                            ...this.state.theme.palette,
                            ...palette,
                        }
                    }
                })
            } else {
                this.setState({
                    time: json.time,
                    interval: json.interval,
                    jitter: json.jitter,
                    stackSize: json.stackSize,
                    rotationRange: json.rotationRange,
                    loading: false, login: false, error: null,
                    init: false, valid: true,
                }, this.setUp)
                this.loadImages(json.images)
            }
        }).catch(err => {
            this.setState({
                loading: false, login: true,
                error: "Une erreur inattendue s'est produite",
                init: false,
            })
            console.error(err)
        })
    }

    loadImages = images => {
        const { time, interval } = this.state
        const { match } = this.props
        this.newImages = this.newImages.concat(images)
        this.nextImage = -1
        this.toRemove = []
        console.log("interval", (time.move+interval)*1000, "next image", this.nextImage, "count", this.images.length)
        this.socket = io.connect(url)
        this.socket.on(match.params.event, data => {
            const id = data.remove ? data.remove : data.image.id
            console.log("receive image event", data)
            if (data.remove||data.image.published!==true) {
                if (this.images.find(i => i.id === id) !== undefined) {
                    this.toRemove.push(id)
                }
                this.newImages = this.newImages.filter(i => i.id !== id)
            } else {
                const image = this.images.find(image => image.id === id)
                if (image === undefined) {
                    const newImage = this.newImages.find(
                        image => image.id === id
                    )
                    if (newImage === undefined) {
                        console.log('new image', data.image)
                        this.newImages.push(data.image)
                    } else {
                        console.log('new image update', data.image)
                        this.newImages = this.newImages.map(
                            i => id === i.id ? {...i, ...data.image} : i
                        )
                    }
                } else {
                    console.log('known image update', data.image)
                    this.images = this.images.map(
                        i => id === i.id ? {...i, ...data.image} : i
                    )
                    this.setState({
                        stacks: this.state.stacks.map(
                            stack => stack.map(
                                i => i && i.id === id ?
                                    {...i, ...data.image} : i
                            )
                        )
                    })
                }
            }
        })
        this.tick()
        this.timeoutId = setInterval(this.tick, (time.move + interval) * 1000)
    }

    doLogin = data => {
        const { match } = this.props
        const { password } = data
        const formData = new FormData()
        formData.append("event", match.params.event)
        formData.append("password", password)
        formData.append("viewer", true)
        this.setState({ loading: true })
        fetch(`${url}/api/login`, {
            method: 'POST', headers: new Headers({
                'x-csrf-token': this.state.csrf,
            }),
            credentials: 'include',
            body: formData,
        }).then(res => {
            if (!res.ok) {
                throw res
            } else {
                return res.json()
            }
        }).then(json => {
            if (!!json.error) {
                this.setState({
                    error: json.error,
                    loading: false,
                    login: true,
                    csrf: json.csrf,
                })
            } else {
                this.setState({
                    login: false,
                    loading: false,
                    error: null,
                    csrf: json.csrf,
                })
                this.getImages()
            }
        }).catch(err => {
            console.error(err)
            this.setState({
                loading: false,
                login: true,
                error: "Une erreur inattendue s'est produite",
            })
        })
    }

    getResolution(dpi) {
        if (!dpi) {
            return 96
        } else {
            const m = /^([0-9]+)(inches|i|"|cm)?$/i.exec(dpi)
            let d = parseInt(m[1])
            if (m[2] === 'cm') {
                d = d / 2.54
            }
            const D = Math.sqrt(
                window.screen.width * window.screen.width +
                window.screen.height * window.screen.height
            )
            return D / d
        }
    }

    componentDidMount = () => {
        if (this.eventValid)
            this.getImages()
    }

    setUp = () => {
        const {
            match, area= { w: 3.483, h: 4.233 }, margin=75,
        } = this.props
        const { stackSize } = this.state
        const rect = this.div.current.getBoundingClientRect()
        const dpi = this.getResolution(match.params.dpi)
        console.log(dpi, rect)
        const cell = {
            width: ( ( area.w * dpi ) + margin ),
            height: ( ( area.h * dpi ) + margin ),
        }
        const rows = Math.trunc(rect.height / cell.height)
        const cols = Math.trunc(rect.width / cell.width)
        const innerWidth = ( cols * cell.width )
        const innerHeight = ( rows * cell.height )
        const xPad = ( rect.width - innerWidth ) / 2
        const yPad = ( rect.height - innerHeight ) / 2
        const startx = xPad + innerWidth / 2 - cell.width
        const starty = -yPad -cell.height
        this.setState({
            margin, rows, cols, cell, dpi, startx, starty,
            count: rows * cols * stackSize,
            stacks: Array(rows * cols).fill(null).map(
                () => Array(stackSize).fill(null)
            ),
        })
        console.log("can display", rows * cols * stackSize, "images", "on", rows, "rows", cols, "cols", stackSize, "stacked")
    }

    onRemove = i => id => () => {
        const { stacks, stackSize } = this.state
        const stack = stacks[i]
        const idx = stack.findIndex(image => !!image && image.id === id)
        console.log(
            "removing", id, "from", stack, "at index", idx,
        )
        this.setState({
            stacks: [
                ...stacks.slice(0, i), [
                    ...stack.slice(0, idx),
                    ...stack.length > stackSize ? [] : [null],
                    ...stack.slice(idx + 1),
                ],
                ...stacks.slice(i + 1)
            ]
        })
    }

    onLoad = i => id => () => {
        const { stacks } = this.state
        const stack = stacks[i]
        this.setState({
            stacks: [
                ...stacks.slice(0, i),
                stack.map(
                    image => image && image.id === id ?
                    {...image, start: true } :
                    image
                ),
                ...stacks.slice(i + 1)
            ],
        })
    }

    render() {
        const { margin, rows, cols, cell, stacks, init, theme, dpi, startx, starty, time, login, error, loading, valid, jitter } = this.state
        const { match } = this.props
        this.eventValid =
            !!match&&!!match.params&&!!match.params.event&&
            /^[0-9a-z]{5,32}$/i.test(match.params.event)
        const msg = this.eventValid ? error :
            "Vérifiez l'URL de l'évènement"
        const viewer = () => (
            <div style={{
                position: 'absolute',
                top: '0',
                bottom: '0',
                left: '0',
                right: '0',
                display: 'flex',
                flexWrap: 'wrap',
                overflow: 'hidden',
                background: `url('/api/background/${match.params.event}')`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover'
            }}>
                {!!rows &&
                    <div
                        style={{
                            width: cols * cell.width,
                            height: rows * cell.height,
                            margin: 'auto',
                            position: 'relative',
                        }}>
                        {stacks.map((stack, i) => (
                            <Stack
                                dpi={dpi}
                                key={`stack-${i}`}
                                margin={margin}
                                x={(i % cols) * cell.width}
                                y={Math.trunc(i / cols) * cell.height}
                                start={{x: startx, y: starty}}
                                time={time}
                                jitter={jitter}
                                event={match.params.event}
                                onRemove={this.onRemove(i)}
                                onLoad={this.onLoad(i)}
                                images={stack} />))}
                    </div>}
                </div>
            )
        const content = () => {
            switch(true) {
                case init && this.eventValid:
                    return <Loading />
                case login === true || !valid:
                    return (
                        <Login
                            valid={this.eventValid && valid}
                            event={match.params.event}
                            loading={loading && this.eventValid}
                            fields={{
                                password: {
                                    type: 'password',
                                    placeholder: "Mot de passe",
                                    error: msg,
                                }
                            }}
                            onLogin={this.doLogin}
                            text="Voir !"
                            />
                    )
                case login === false:
                    return viewer()
                default:
                    return <div />
            }
        }
        return (
            <div
                className="container"
                style={{height: '100vh'}}
                ref={this.div}>
                <MuiThemeProvider
                    theme={createMuiTheme(theme)}>
                    {content()}
                </MuiThemeProvider>
            </div>
        )
    }
}
