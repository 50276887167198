import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import LinearProgress from '@material-ui/core/LinearProgress'
import { Icon } from './Images'

class Login extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            error: null,
            ...Object.keys(props.fields).reduce(
                (fields, field) => ({
                    ...fields,
                    [field]: props.fields[field].value
                }), {},
            )
        }
    }

    componentDidUpdate(props) {
        const values = Object.keys(props.fields).reduce(
            (fields, field) => (
                props.fields[field].value !==
                undefined &&
                props.fields[field].value !==
                this.props.fields[field].value ? {
                    ...fields,
                    [field]: props.fields[field].value,
                } : fields
            ), {}
        )
        if (Object.keys(values).length) {
            this.setState(values)
        }
    }

    render() {
        const {
            valid, loading, onLogin, event, fields, text
        } = this.props
        const data = this.state
        return (
            <Paper>
                {valid &&
                    <div className="title">
                        <Icon
                            src={`/api/icon/${event}`}
                            alt={event} />
                    </div>}
                <form
                    onSubmit={e => {
                        e.preventDefault();
                        !!onLogin && onLogin(this.state)
                    }}>
                    {Object.keys(fields).map(
                        id => {
                            const field = fields[id]
                            return (
                                <TextField
                                    key={id}
                                    id={id}
                                    onChange={e =>
                                        this.setState({
                                            [id]: e.target.value
                                        })
                                    }
                                    type={field.type || 'text'}
                                    value={data[id] || ""}
                                    autoComplete="false"
                                    autoFocus
                                    fullWidth
                                    disabled={!!loading || !valid}
                                    placeholder={field.placeholder}
                                    error={
                                        !!field.error
                                    }
                                    helperText={
                                        !!field.error &&
                                        field.error
                                    } />
                            )
                        }
                    )}
                    <div style={{
                            position: "relative",
                            top: "0px",
                        }}>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={!!loading || !valid}>
                            {text}
                        </Button>
                        {!!loading &&
                            <LinearProgress
                                style={{
                                    width: "100%",
                                    position: "absolute",
                                    bottom: "0px",
                                }} />}
                    </div>
                </form>
            </Paper>
        )
    }
}

Login.propTypes = {
    event: PropTypes.string.isRequired,
    valid: PropTypes.bool.isRequired,
    loading: PropTypes.bool,
    onLogin: PropTypes.func,
    fields: PropTypes.objectOf(
        PropTypes.shape({
            type: PropTypes.oneOf(['text', 'password']),
            placeholder: PropTypes.string,
            value: PropTypes.string,
            error: PropTypes.string,
        }).isRequired,
    ),
}

export default Login
