import React from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'

export default () => (
    <div style={{
        maxWidth: "400px",
        width: "95vw",
        textAlign: "center",
    }}>
        <div style={{
                marginBottom: "1em",
            }}>
            Chargement en cours...
        </div>
        <LinearProgress />
    </div>
)
